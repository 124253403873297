@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Roboto:wght@400;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --primary-text-color:#183b56;
    --secondary-text-color:#577592;
    --accent-color:#2294ed;
    --accent-color-dark:#1d69a3;
    --padding-inline-section: 20px;

}

body{
    font-family: 'Poppins', sans-serif;
    color: var(--primary-text-color);
}

.company-title{
    font-size: 2rem;
    font-weight: bold;
}

h1{
    font-size: 3rem;
}

h2{
    font-size: 2rem;
}

h3{
    font-size: 1.5rem;
}

p{
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    color: var(--secondary-text-color);
    line-height: 1.8rem;

}
a{
    text-decoration: none;
    display: inline-block;
}

/* utility classes */

.small-bold-text{
    font-size: 1rem;
    font-weight: 500;

}

.container{
    max-width: 1180px;
    margin-inline: auto ;
    padding-inline: var(--padding-inline-section);
}
.flex{
    display: flex;
    align-items: center;
}

.primary-button{
    background-color: var(--accent-color);
    border-radius: 6px;
    font-weight: 700;
    color: white !important;
    padding: 12px 24px;
    box-shadow: 0 0 2px var(--secondary-text-color);
    transition: 0.2s ease-out;
    text-align: center;
}

.primary-button:hover{
    background-color: var(--accent-color-dark);
    
}

.secondary-button{
    border: 0.5px solid var(--secondary-text-color);
    border-radius: 6px;
    font-weight: 500;
    padding: 12px 24px;
    transition: 0.2s ease-out;
    color: var(--primary-text-color);
}
.secondary-button:hover{
    border-color: var(--accent-color);
    color: var(--accent-color) !important;
}
/* banner */
.banner-text{
    color: white;
    text-align: center;
    padding: 15px 30px;
}

/* nav bar */
.top-banner{
    background-image: url('./assets/asset\ 30.png') ;
    background-size: 300px;
    background-color: #4fb3d4;
}
.main-nav{
    margin-top: 25px;
    justify-content: space-between;    
    position: relative; 
   
}

.company-logo img{
    width: 200px;
}

.nav-links{
    flex-basis: 730px;
}

.nav-links ul{
    list-style: none;
    justify-content: end;
    gap: 40px;
}

.hover-link{
    color: var(--primary-text-color);
    transition: 0.2s ease-out;
}

.hover-link:hover{
    color: var(--accent-color);   
}
/* header */

header {
    padding: 50px var(--padding-inline-section) 0;
}

.header-section {
    justify-content: center;
    gap: 50px;
}

.header-left {
    width: 50vw;
}

.header-left h1 {
    margin-top: 20px;
}

.get-started-btn {
    margin-top: 20px;
}

.header-right img {
    width: 100%;
}
.get-started-btn{
    margin-top: 20px;
}
.nav-toggle{
    display: none;
}
.small-bold-text #email{
    color: white    ;
}
.small-bold-text #email:hover{
    text-decoration: underline;
}


@media screen and (max-width:991px) {
    .nav-toggle{
        display: block;
        margin-right: 20px;
    }
    .main-nav{
        overflow: initial;
    }
    .nav-links{
       position: absolute;
       background-color: #fff;
       left: -100%;
       top: 100%;   
       width: 100%;
       padding-block: 20px;
    }
    .nav-links-active{
        left: 0;
    }
    .nav-links ul{
        flex-direction: column;
    }
    
}

@media screen and (max-width:767px) {
    .header-section{
        flex-direction: column-reverse;
    }
   
    .header-left {
        width: 100%;
        text-align: center;
    }
    .primary-button{
        width: 100%;
    }
}

@media screen and (max-width:479px) {
    :root{
        --padding-inline-section:10px;
    }
}