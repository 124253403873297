.client-page{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
}
.client-page .greet{
    font-size: 50px;
    font-weight: bold;

}
.feedback-title{
    font-size: 24px;
    font-weight: 600;
    margin: 2% 0;
}
.outer-container-feedback{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.inner-container{
    
    padding: 20px;
    width: 25%;
    min-height: 200px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    background: radial-gradient(#76b2fe, #b69efe);
    display: flex;
    flex-direction: column;
    gap: 6px;
   
}

@media screen and (max-width:767px) {
    .client-page .greet{
        font-size: 35px;
    }
    .feedback-title{
        font-size: 21px;
        padding: 1%;
    }
    .inner-container{
        width: 80%;
        margin: 4%;
    }
    .outer-container-feedback{
        flex-direction: column;

    }
}