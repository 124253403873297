.outer-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.steps-to-register-title{
    font-size: 30px;
    font-weight: 700;
    width: 80%;
    margin-top: 10px;
}

.steps-to-register{
    margin: 10px;
    width: 80%;
}

.step-numbers{
    margin: 2%;
    font-size: 20px;
    font-weight: 500;
}