.button{

    margin:10px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    background: radial-gradient(#1fe4f5, #3fbafe);
    font-size: 20px;
    border: none;
}

.buttons{
    position: absolute;
    top: 0;
    right: 0;
}

.container{
    max-width: 1180px;
    margin-inline: auto ;
    padding-inline: var(10px);
    overflow: hidden;
}